/**
 *
 * @param userAuthorisations : array
 * @param wantedRole : String
 */
export function userHasRole(userAuthorisations, wantedRole) {
	let isRoleArray = Array.isArray(wantedRole);
	if (Array.isArray(userAuthorisations)) {
		for (let i = 0; i < userAuthorisations.length; i++) {
			const role = userAuthorisations[i].authorization.role;
			if (!isRoleArray) {
				if (role === wantedRole ) return true;
			} else {
				for (const wr of wantedRole) {
					if (role === wr) return true;
				}
			}
		}
		return false;
	} else return false;
}

/**
 *
 * @param userAuthorisations : array
 * @param wantedRole : String
 */
export function getScopeForAuthorization(userAuthorisations, wantedRole) {
	if (Array.isArray(userAuthorisations)) {
		for (let i = 0; i < userAuthorisations.length; i++) {
			if (userAuthorisations[i].authorization.role === wantedRole) {
				return this.userAuthorisations[i].scope;
			}
		}
		return null;
	} else return null;
}
