<template>
  <v-card>

    <v-container>

      <!--Liste des conditions-->
      <v-row>
        <v-col class="d-flex align-center">
          <v-spacer/>

          <v-chip v-if="hasMoreThanOneCondition" @click="toggleBinaryOperator"
                  @click:close="toggleBinaryOperator" close
                  close-icon="mdi-swap-horizontal" color="white"
                  class="elevation-1">
            {{ binaryOperator === 'AND' ? 'ET' : 'OU' }}
          </v-chip>

          <v-btn-toggle dense rounded borderless class="ml-2 elevation-1">
            <v-btn text @click="requirementCount++">+</v-btn>
            <v-btn :disabled="!hasMoreThanOneCondition" text @click="requirementCount--">-</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <div v-for="r in requirementCount" :key="'req-'+r">
        <v-row dense>
          <v-col>
            <request-builder-part
                :id="'req-builder-filter-part-'+r"
                :ref="'filter-'+r"
                :is-removable="r > 0"
                :table-structure="tableStructure"
            />
          </v-col>
        </v-row>

      </div>

      <v-row no-gutters v-if="showLastBuiltRequest && lastBuildRequest">
        <v-col>
          <pre class="pa-2">{{ lastBuildRequest }}</pre>
        </v-col>
      </v-row>

    </v-container>

  </v-card>
</template>

<script>
import RequestBuilderPart from "./RequestBuilderPart.vue";


export default {
  name: "RequestBuilder",
  components: {RequestBuilderPart},
  data() {
    return {
      requirementCount: 1,
      binaryOperator: 'AND',
      lastBuildRequest: null,
    }
  },
  props: {
    tableStructure: {
      type: Object,
      required: true,
    },
    showLastBuiltRequest: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  watch: {},
  computed: {
    hasMoreThanOneCondition() {
      return this.requirementCount > 1;
    }
  },
  methods: {
    buildFilterString() {
      let filterString = "";
      for (let i = 0; i < this.requirementCount; i++) {
        let ref = this.$refs['filter-' + (i + 1)][0]; // Le [0] c'est parce que, pour une raison inconnue, Vue retourne une liste de composants avec uniquement le composant demandé
        filterString += ref.buildPart();
        if (i < this.requirementCount - 1) {
          filterString += ` ${this.binaryOperator} `;
        }
      }
      this.lastBuildRequest = filterString;
      return filterString;
    },
    toggleBinaryOperator() {
      this.binaryOperator = this.binaryOperator === 'AND' ? 'OR' : 'AND';
    }
  }
}
</script>

<style scoped>

</style>
