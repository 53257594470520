<template>
  <v-container fluid>
    <v-card class="mb-6">
      <v-card-title>
        Cloud Consult
      </v-card-title>
      <v-card-text v-if="!isUserCompany && !isUserSupport">
        Vous ne disposez pas des droits nécessaire à l'utilisation de YodaCloud Consult.
      </v-card-text>
      <v-card-text v-else>
        <y-company-select outlined v-model="selectedCompany" v-if="!isUserCompany" class="mb-6"/>

        <v-select class="mb-6" hide-details outlined dense :loading="loading.tables" :disabled="!wakandaTables" :error="errors.fetchingTables" :error-messages="errors.fetchingTables ? ['Impossible de récuperer le modèle du cloud de la société sélectionnée'] : []" :items="wakandaTables" v-model="selectedTable" label="Table"/>

        <v-tabs centered dense :disabled="!selectedTable" v-model="selectedRequestTypeIndex">
          <v-tab v-for="r in requestTypes" :key="r.value">
            {{r.text}}
          </v-tab>
        </v-tabs>

        <v-tabs-items class="pt-6" v-model="selectedRequestTypeIndex">
          <v-tab-item>
            <v-text-field outlined dense v-model="requestedIdentifier" label="UUID"/>
          </v-tab-item>
          <v-tab-item>
            <span v-if="!selectedTableStructure">Sélectionnez une table pour commencer</span>
            <request-builder show-last-built-request v-else :table-structure="selectedTableStructure" ref="requestBuilder"/>
          </v-tab-item>
          <v-tab-item>
            <v-text-field v-model="requestQueryFilter" label="Requête"/>
          </v-tab-item>
          <v-tab-item>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-spacer/><v-btn :disabled="!readyToRequest" color="primary" @click="onSendRequestClicked" :loading="loading.requestingWakanda">Envoyer la requête</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <v-alert v-if="errors.requestingWakanda" type="error" dismissible>
      Impossible de réaliser la requête.
    </v-alert>

    <wakanda-result-viewer v-if="requestResult && !loading.requestingWakanda" :value="requestResult" @delete="onDeleteRequested" :allow-delete="isUserSupportWithFullAccess"/>

    <v-dialog :value="!!uuidToDelete" max-width="400">
      <v-snackbar color="error" v-model="errors.deleting" bottom>Une erreur est survenue lors de la suppression de l'enregistrement {{uuidToDelete}} pour la table {{selectedTable}}</v-snackbar>
      <v-card>
        <v-card-title>Êtes-vous sûr•e ?</v-card-title>
        <v-card-text>
          Voulez-vous supprimer la donnée UUID <span class="font-weight-bold">{{ uuidToDelete }}</span> pour la table <span class="font-weight-bold">{{ selectedTable }}</span> ?
        </v-card-text>
        <v-card-text>
          Cette action est irreversible.
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="uuidToDelete = null">Annuler</v-btn>
          <v-spacer/>
          <v-btn txt color="error" @click="onDeleteConfirmed">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

  import {mapState} from "vuex";
  import WakandaResultViewer from "@/components/WakandaResultViewer.vue";
  import RequestBuilder from "@/components/RequestBuilder.vue";
  import {getScopeForAuthorization, userHasRole} from "@/business/auth-business";

  export default {
    name: 'HomeView',

    components: {
      RequestBuilder,
      WakandaResultViewer
    },

    data: () => ({
      errors: {
        fetchingTables: false,
        fetchingTableStructure: false,
        requestingWakanda: false,
        deleting: false,
      },
      loading: {
        tables: false,
        requestingWakanda: false,
        tableStructure: false,
        deleting: false,
      },
      selectedCompany: null,
      selectedRequestTypeIndex: 0,
      selectedTable: null,
      selectedTableStructure: null,

      requestedIdentifier: "",
      builtRequestQueryFilter: "",
      requestQueryFilter: "",

      requestTypes: [
        {text: 'Selection par identifiant', value: 'byId'},
        {text: 'Requête', value: 'assistedPage'},
        {text: 'Requête personnalisée', value: 'page'},
        {text: 'Tout', value: 'all'},
        // {text: 'Requete native brute', value: 'raw'}
      ],
      wakandaTables: null,

      requestResult: null,

      uuidToDelete: null,
    }),
    mounted() {
      if (this.isUserCompany) {
        this.selectedCompany = {uuid: getScopeForAuthorization(this.userAuthorisations, "company")?.companyUuid}
      }
    },
    watch: {
      isUserCompany(v) {
        if (v) {
          this.selectedCompany = {uuid: getScopeForAuthorization(this.userAuthorisations, "company")?.companyUuid}
        }
      },
      selectedCompany(newVal) {
        if (newVal && newVal.uuid) {
          this.refreshAvailableTables()
        } else {
          this.wakandaTables = null;
          this.selectedTable = null;
        }
      },
      selectedTable(newVal) {
        if (newVal) {
          this.fetchTableStructure(newVal);
        } else {
          this.selectedTableStructure = null;
        }
      }
    },
    computed: {
      ...mapState("auth", ['userAuthorisations']),
      selectedRequestType() {
        return this.selectedRequestTypeIndex >= 0 ? this.requestTypes[this.selectedRequestTypeIndex].value : null;
      },
      readyToRequest() {
        return this.selectedCompany && this.selectedTable && this.selectedRequestType
      },
      isUserSupport() {
        return userHasRole(this.userAuthorisations, ["root", "support-read-only", "support-full-access"]);
      },
      isUserSupportWithFullAccess() {
        return userHasRole(this.userAuthorisations, ["root", "support-full-access"]);
      },
      isUserCompany() {
        return userHasRole(this.userAuthorisations, "company");
      },
    },
    methods: {

      refreshAvailableTables() {
        this.loading.tables = true;
        this.errors.fetchingTables = false;
        this.$http.get(this.$config.apiUrl+'/v1/companies/'+this.selectedCompany.uuid+'/catalog')
            .then(r => {
              this.wakandaTables = r.data.dataClasses.map(t => t.name);
              this.selectedTable = this.wakandaTables[0];
            })
            .catch(e => {
              console.log(e)
              this.errors.fetchingTables = true;
              this.wakandaTables = null;
            })
            .finally(() => this.loading.tables = false)
      },
      fetchTableStructure(tableName) {
        this.loading.tableStructure = true;
        this.errors.tableStructure = false;
        this.$http.get(this.$config.apiUrl+'/v1/companies/'+this.selectedCompany.uuid+'/catalog/'+tableName)
            .then(r => {
              this.selectedTableStructure = r.data;
            })
            .catch(e => {
              console.log(e)
              this.errors.tableStructure = true;
              this.selectedTableStructure = null;
            })
            .finally(() => this.loading.tableStructure = false)
      },
      onSendRequestClicked() {
        this.loading.requestingWakanda = true;
        this.errors.requestingWakanda = false;
        let url = this.$config.apiUrl+'/v1/companies/'+this.selectedCompany.uuid+'/native/'+this.selectedTable;
        switch (this.selectedRequestType) {
          case 'byId':
            url += "/"+this.requestedIdentifier;
            break;
          case 'page':
            url += "?query="+this.requestQueryFilter;
            break;
          case 'assistedPage':
            url += "?query="+this.$refs.requestBuilder.buildFilterString();
            break;
        }
        this.$http.get(url)
            .then(r => {
              this.requestResult = r.data;
            })
            .catch(e => {
              console.log(e)
              this.errors.requestingWakanda = true;
              this.requestResult = null;
            })
            .finally(() => this.loading.requestingWakanda = false)
      },
      onDeleteRequested(valueToDelete) {
        this.uuidToDelete = valueToDelete['__KEY'];
      },
      onDeleteConfirmed() {
        if (this.isUserSupportWithFullAccess) {
          this.loading.deleting = true;
          this.errors.deleting = false;
          let url = `${this.$config.apiUrl}/v1/companies/${this.selectedCompany.uuid}/raw/rest/${this.selectedTable}(${this.uuidToDelete})?$method=delete`;
          this.$http.get(url)
              .then(() => {
                this.uuidToDelete = null;
                this.onSendRequestClicked();
              }) // On refresh
              .catch(e => {
                console.log(e)
                this.errors.deleting = true;
              })
              .finally(() => this.loading.deleting = false)
        }
      }
    }
  }
</script>
