<script>
export default {
  name: "WakandaResultViewer",
  props: {
    value: {
      type: Object,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    displayRawValue: false,
    uuidToDelete: null,
  }),
  computed: {
    isError() {
      return !!this.value['__ERROR'];
    },
    errorType() {
      return !this.isError ? null :
          this.value['__ERROR'][0].errCode === 1543 ? 'not-found' : 'unknown';
    },
    isPage() {
      return !!this.value['__ENTITIES'];
    },
    // totalPageCount() {
    //   return !this.isPage ? null :
    //       Math.ceil(this.value['__COUNT'] / this.value['__SENT']);
    // },
    // currentPageNumber() {
    //   return !this.isPage ? null :
    //       Math.ceil(this.value['__FIRST'] / this.value['__SENT']) + 1;
    // },
    cardColor() {
      return !this.isError ? null :
          this.errorType === 'unknown' ? 'alert' : 'warning';
    }
  },
  methods: {
    onDeleteClicked(toDelete) {
      this.$emit('delete', toDelete)
    }
  }
}
</script>

<template>

  <v-card :color="cardColor">
    <v-card-subtitle class="d-flex align-center">
      <v-spacer/>Réponse brute<v-switch class="mt-0 ml-2" dense hide-details v-model="displayRawValue"></v-switch>
    </v-card-subtitle>
    <v-card-text v-if="displayRawValue">
      <pre>{{ value }}</pre>
    </v-card-text>


    <template v-else-if="isError">
      <v-card-text v-if="errorType === 'not-found'">Aucune donnée trouvée pour l'identifiant demandé</v-card-text>
      <v-card-text v-else>Erreur inconnue</v-card-text>
    </template>


    <div v-else-if="isPage">
      <v-card-subtitle class="text-right">{{ value['__COUNT'] }} résultats, {{ value['__SENT'] }} affichés.</v-card-subtitle>
<!--      Page {{ currentPageNumber }} sur {{ totalPageCount }}-->
      <v-card-text>
        <v-simple-table dense>
          <thead>
          <tr>
            <th v-for="(v,k) of value['__ENTITIES'][0]" :key="v">{{k}}</th>
            <th v-if="allowDelete"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(line) in value['__ENTITIES']" :key="line['__KEY']">
            <td v-for="(v,k) of line" :key="k+line['__KEY']">
              {{v}}
            </td>
            <td>
              <v-btn icon x-small color="error" @click="onDeleteClicked(line)"><v-icon>mdi-trash-can</v-icon></v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </div>


    <div v-else> <!--  données unique  -->
        <v-card-text>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Propriété</th>
                <th>Valeur</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) of value" :key="k">
                <th>{{ k }}</th>
                <td>{{ v }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-btn class="mt-2" text small v-if="allowDelete && !!value['__KEY']" block color="error" @click="onDeleteClicked(value)">Supprimer cette donnée</v-btn>
        </v-card-text>
    </div>

  </v-card>

</template>

<style scoped>

</style>
