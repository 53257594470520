<template>
  <div class="line">

    <v-select :items="tableStructure.dataClasses[0].attributes" class="pa-1" label="Sujet" solo
              v-model="selectedProperty" item-text="name" return-object/>

    <v-select  :items="availableOperators" class="pa-1" label="Opérateur" solo v-model="operator" return-object item-text="text"/>

    <div class="v-card" v-if="valueType==='boolean'">
      <v-checkbox class="pa-1" :label="value" solo v-model="value" true-value="true" false-value="false"/>
    </div>
    <!--    <v-text-field v-else-if="valueType==='long' && this.isSingleValue" type="number" :background-color="color" class="pa-1" label="Valeur" solo v-model="value"/>-->
    <!--    <v-select v-else-if="valueType==='enum' && this.isSingleValue" :items="enumForCurrentKey" solo v-model="value"/>-->
    <v-text-field v-else  class="pa-1" label="Valeur" solo v-model="value"/>

    <!--        <v-btn @click="$emit('delete', requirement)" class="ma-1 elevation-2" color="error" height="48"-->
    <!--               v-if="isRemovable"-->
    <!--               x-small>-->
    <!--            <v-icon>mdi-delete</v-icon>-->
    <!--        </v-btn>-->
  </div>
</template>

<script>

const EQ = {value: "EQUALS", text: "="}
const NOT_EQ = {value: "NOT_EQUALS", text: "!="}
const GT = {value: "GT", text: ">"}
const GT_OR_EQ = {value: "GT_OR_EQ", text: ">="}
const LT = {value: "LT", text: "<"}
const LT_OR_EQ = {value: "LT_OR_EQ", text: "<="}
// const CONTAINS = {value: "CONTAINS", text: "contient"}
// const NOT_CONTAINS = {value: "NOT_CONTAINS", text: "ne contient pas"}
// const IN_CSV = {value: "IN_CSV", text: "dans le CSV"}
// const NOT_IN_CSV = {value: "NOT_IN_CSV", text: "absent du CSV"}
const OPERATORS_ALL = [EQ, NOT_EQ, GT, GT_OR_EQ, LT, LT_OR_EQ,]; //CONTAINS, NOT_CONTAINS, IN_CSV, NOT_IN_CSV
const OPERATORS_BOOLEAN = [EQ, NOT_EQ]
const OPERATORS_STRING = [EQ, NOT_EQ,] //CONTAINS, NOT_CONTAINS, IN_CSV, NOT_IN_CSV
const OPERATORS_NUMBER = [EQ, NOT_EQ, GT, GT_OR_EQ, LT, LT_OR_EQ,] //IN_CSV, NOT_IN_CSV

export default {
  name: "RequestBuilderPart",
  data() {
    return {
      selectedProperty: null,
      value: "",
      operator: EQ,

    }
  },
  props: ['color', 'isRemovable', 'tableStructure'],
  mounted() {
    this.selectedProperty = this.tableStructure.dataClasses[0].attributes[0];
  },
  computed: {
    valueType() {
      return this.selectedProperty?.type;
    },
    availableOperators() {
      switch (this.valueType) {
        case 'string':
        case 'word':
        case 'uuid':
          return OPERATORS_STRING;
        case 'long':
        case 'number':
        case 'date':
          return OPERATORS_NUMBER;
        case 'bool':
          return OPERATORS_BOOLEAN;
        default:
          return OPERATORS_ALL;
      }
    },
  },
  methods: {
    buildPart() {
      if (this.selectedProperty) {
        let s = this.selectedProperty.name + " " + this.operator.text + " " + this.value;
        return s;
      } else return "";
    }
  }
}
</script>

<style scoped>
.line {
  display: flex;
  flex-direction: row;
}

</style>
